import React from 'react'

export default function Arrow(props) {
    return (
        <svg width={25} height={10} viewBox="0 0 25 10" {...props} fill='rgba(168, 208, 230, 1)'>
            <title>{"arrow"}</title>
            <path
                d="M20.696.155l.084.076 4 4.21a.82.82 0 01.073 1.029l-.073.088-4 4.21a.724.724 0 01-1.06 0 .82.82 0 01-.073-1.027l.073-.089 2.719-2.863H.75C.336 5.79 0 5.436 0 5c0-.4.282-.73.648-.782L.75 4.21l21.689-.001-2.72-2.862A.82.82 0 0119.648.32L19.72.23a.723.723 0 01.976-.076z"
                fill="#24305E"
                fillRule="nonzero"
            />
        </svg>
    )
}
