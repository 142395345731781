import React from 'react'

export default function Right(props) {
    return (
        <svg width={30} height={22} viewBox="0 0 10 22" {...props} cursor='pointer'>
            <title>{"chev/right"}</title>
            <path
                d="M.563.453a.7.7 0 01.913.034l.07.076 8 10a.7.7 0 01.061.785l-.06.09-8 10a.7.7 0 01-1.152-.79l.058-.085 7.65-9.564-7.65-9.562A.7.7 0 01.487.524l.076-.07z"
                fill="#363636"
                fillRule="nonzero"
            />
        </svg>
    )
}
