import React from 'react'
import { useTranslation } from 'react-i18next'
import CardSellingPoint from 'src/components/cards/CardSellingPoint'

export default function SellingPointGallery(){
    const { t } = useTranslation();

    return (
        <div className="container mx-auto relative px-4 lg:px-0">
            <div className='flex flex-wrap -mt-40 md:-mx-2 lg:mx-0'>
                {t('home.sellingPoints', {returnObjects: true}).map((sellingPoint, i) => (
                    <CardSellingPoint key={i} sellingPoint={sellingPoint} />
                ))}
            </div>
        </div>
    )
}
