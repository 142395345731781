import React from 'react'
import 'src/components/atoms/Glitch.css'

export default function Glitch({ image, width, height, type, active }) {
    const animated = type === 'controlled' && active;

    return (
        <div
            className={`glitch ${type}${animated ? ' animate' : ''}`}
            style={{ width: width || '100%', height: height || '100%' }}
        >
            <div className="glitch__img" style={{backgroundImage: `url(${image})`}} />
            <div className="glitch__img" style={{backgroundImage: `url(${image})`}} />
            <div className="glitch__img" style={{backgroundImage: `url(${image})`}} />
            <div className="glitch__img" style={{backgroundImage: `url(${image})`}} />
            <div className="glitch__img" style={{backgroundImage: `url(${image})`}} />
        </div>
    )
}

Glitch.defaultProps = {
    type: 'hover',
    active: false
};
