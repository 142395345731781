import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import MarketingLayout from 'src/components/layout/MarketingLayout'
import Hero from 'src/components/sections/home/Hero'
import SellingPointGallery from 'src/components/sections/home/SellingPointGallery'
import FindYourTeacher from 'src/components/sections/home/FindYourTeacher'
import Instruments from 'src/components/sections/home/Instruments'
import Reviews from 'src/components/sections/home/Reviews'
import InstrumentHookUp from 'src/components/sections/home/InstrumentHookUp'
import TryNow from 'src/components/sections/home/TryNow'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import { useLocation } from '@reach/router';
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function Home(props) {
    const { setTheme } = useContext(ThemeContext);
    const { showResetPassword } = useContext(ProfileContext);
    const { t } = useTranslation();
    const location = useLocation();
    const { resetPassword } = useLocalizedRoutes();

    useEffect(() => {
        if(resetPassword === location.pathname) {
            showResetPassword()
        }
    }, [resetPassword]);

    useEffect(() => setTheme('normal'), [])

    return (
        <MarketingLayout {...props} title={t('home.title')}>
            <Hero />
            <SellingPointGallery />
            <FindYourTeacher />
            <Instruments />
            <Reviews />
            <InstrumentHookUp />
            <TryNow />
        </MarketingLayout>
    )
}
