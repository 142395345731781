import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import Left from 'src/components/icons/Left'
import Right from 'src/components/icons/Right'
import Heading from 'src/components/typography/Heading'
import protraitOne from 'src/images/portrait-1.jpg'
import protraitTwo from 'src/images/portrait-2.jpg'
import protraitThree from 'src/images/portrait-3.jpg'

const PHOTOS = [
    { name: 'Kelly', img: protraitTwo },
    { name: 'John', img: protraitOne },
    { name: 'Louis', img: protraitThree },
];

export default function GalleryReviews(){
    const { t } = useTranslation();
    const [reviewsList, setReviewsList] = useState(t('home.reviews', { returnObject: true}))

    function nextReview() {
        const first = reviewsList.shift()
        let newArray = [...reviewsList, first]
        setReviewsList(newArray)
    }

    function previousReview(){
        const last  = reviewsList.pop()
        let newArray = [last, ...reviewsList]
        setReviewsList(newArray)
    }

    function renderPhotos(){
        let currReview = reviewsList[0]

        return PHOTOS.map(photo => (
            <img
                key={photo.name}
                src={photo.img}
                alt={`review from ${currReview.name}`}
                className={`avatar mx-2 ${currReview.name === photo.name ? 'border-2 border-red-600' : ''}`}
            />
        ))
    }

    return(
        <div>
            <div className="flex justify-center my-8">
                {renderPhotos()}
            </div>
            <div className="flex justify-between w-full lg:w-2/3 mx-auto">
                <div className="flex items-center">
                    <Left onClick={previousReview}/>
                </div>
                <div className="mx-2 lg:mx-16 text-center">
                    <Heading type='caption'>{reviewsList[0].review}</Heading>
                    <div className="flex justify-center my-8 text-center">
                        <p className="text-xl font-semibold mx-2">{reviewsList[0].name}</p>
                        <p className="text-xl font-semibold text-red-600">{reviewsList[0].instrument} {t('home.lessons')}</p>
                    </div>
                </div>
                <div className="flex items-center">
                    <Right onClick={nextReview}/>
                </div>
            </div>
        </div>
    )
}
