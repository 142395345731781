import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import GalleryInstruments from 'src/components/galleries/GalleryInstruments'
import { ArrowedLink } from 'src/components/buttons/Link'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function Instruments(){
    const { t } = useTranslation();
    const { findTeacher } = useLocalizedRoutes();

    return (
        <div className="container mx-auto px-4 my-8 lg:my-24">
            <div className="lg:flex justify-between items-center mb-10">
                <div>
                    <div className='mb-4'>
                        <Heading type='sub-title'>{t('home.whatToLearn')}</Heading>
                    </div>
                </div>
                <div className="mt-4 lg:mt-0">
                    <ArrowedLink
                        route={findTeacher}
                    >{t('home.moreInstruments')}</ArrowedLink>
                </div>
            </div>
            <GalleryInstruments />
        </div>
    )
}
