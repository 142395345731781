import React, { useState } from 'react'
import { Link } from 'gatsby'
import Glitch from 'src/components/atoms/Glitch'
import useLocalizedRoutes from '../../hooks/useLocalizedRoutes'
import {usePageContext} from "../../context/PageContext";

export default function CardTeacher({teacher}){
    const [animated, setAnimated] = useState(false);
    const { teacherProfile } = useLocalizedRoutes();
    const { lang } = usePageContext()

    return (
        <Link
            to={`${teacherProfile}/${teacher.id}`}
            onMouseOver={() => setAnimated(true)}
            onMouseOut={() => setAnimated(false)}
            className="w-64 mr-6 lg:mr-0 lg:w-3/12"
        >
            <Glitch
                type={'controlled'}
                active={animated}
                image={teacher.profile_picture_url}
                width={300}
                height={450}
            />
            <p className='text-3xl font-bold my-2'>{`${teacher.first_name} ${teacher.last_name}`}</p>
            <p className='text-grey-600'>{teacher.instruments[0].translations[lang]}</p>
        </Link>
    )
}
