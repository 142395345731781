import React from 'react'
import CardTeacher from 'src/components/cards/CardTeacher'

export default function GalleryTeachers({teachers}){
    return (
        <div className="flex lg:justify-between pb-8 w-330 md:w-150 lg:w-full">
            {teachers.map((teacher,index) => <CardTeacher key={index} teacher={teacher}/>)}
        </div>
    )
}
