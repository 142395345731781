
import React from 'react'
import { navigate } from 'gatsby'
import ButtonPrimary from 'src/components/buttons/Primary'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { usePageContext } from 'src/context/PageContext'

export default function GalleryInstruments(){
    const { findTeacher } = useLocalizedRoutes();
    const { translatedInstruments } = usePageContext();
    const selectedInstruments = [];
    const selectedInstrumentIds = [
        'acousticGuitar',
        'electricGuitar',
        'ukulele',
        'dj',
        'produceAbleton',
        'sing',
        'piano'
    ];

    for (const translatedInstrument of translatedInstruments) {
        for (const selectedId of selectedInstrumentIds) {
            if (selectedId === translatedInstrument.key) {
                selectedInstruments.push(translatedInstrument);
            }
        }
    }

    return(
        <div className="">
            {selectedInstruments.map((instrument, key) =>
            (
                <ButtonPrimary
                    key={key}
                    large
                    className="inline-block mr-4 mb-4 capitalize"
                    onClick={() => navigate(findTeacher, { state: { instrument }})}
                >{instrument.label}</ButtonPrimary>
            ))}
            <ButtonPrimary
                large
                className="inline-block mr-4 mb-4 capitalize"
                onClick={() => navigate(findTeacher)}
            >...</ButtonPrimary>
        </div>
    )
}
