import React from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import Heading from 'src/components/typography/Heading'
import PrimaryButton from 'src/components/buttons/Primary'

export default function TryNow(){
    const { t } = useTranslation();
    const { findTeacher } = useLocalizedRoutes();

    return (
        <div className="px-4 my-24 bg-baby-blue-600 py-20 w-full">
            <div className='text-center text-white'>
                <Heading type='head-title'>{t('home.tryNow')}</Heading>
            </div>
            <div className='text-center mt-10'>
                <PrimaryButton
                    onClick={() => navigate(findTeacher)}>
                    {t('home.findYourTeacher')}
                </PrimaryButton>
            </div>
        </div>
    )
}
