import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import Paragraph from 'src/components/typography/Paragraph'
import VideoHero from 'src/components/atoms/VideoHero'
import './Home.css'
import { SecondaryButton } from 'src/components/buttons/Primary'
import Modal from 'src/components/modal/Modal'

export default function Hero() {
    const { t } = useTranslation();
    const [videoVisible, setVideoVisible] = useState(false);

    return (
        <div className="bg-baby-blue-600 hero-home">
            <div className="container mx-auto px-4 mt-0 pb-40">
                <div className="flex items-center py-12 lg:py-32 relative">
                    <div className="lg:w-7/12 relative flex flex-col justify-between z-30">
                        <div className="mb-12 text-center lg:text-left">
                            <Heading type='head-title-wrap'>
                                <span className="text-white">{t("home.online")} </span>
                                <span className="text-egg-shell-600">
                                    {t("home.musicLessons")}{" "}
                                </span>
                            </Heading>
                            <Heading type='head-title'>
                                <span className="text-white">{t("home.on")} </span>
                                <span className="text-dark-blue-600">{t("home.yourRythm")}</span>
                            </Heading>
                        </div>
                        <div className="lg:pr-32 text-center lg:text-left">
                            <Paragraph type='intro-blue'>{t("home.intro")}</Paragraph>
                            <div className="mt-8">
                                <SecondaryButton
                                    onClick={() => setVideoVisible(true)}>
                                    {t("home.playVideo")}
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                    <div className="hidden lg:block absolute right-0 w-1/2">
                        <VideoHero />
                    </div>
                </div>
            </div>
            <Modal
                active={videoVisible}
                onClickClose={() => setVideoVisible(false)}
            >
                <iframe width="560" height="315" src="https://www.youtube.com/embed/wcgwLcitEPU?autoplay=1" frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
            </Modal>
        </div>
    )
}
