import React from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import Heading from 'src/components/typography/Heading'
import Paragraph from 'src/components/typography/Paragraph'
import { SecondaryButton } from 'src/components/buttons/Primary'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'

export default function CardSellingPoint({sellingPoint}) {
    const { t } = useTranslation();
    const { findTeacher } = useLocalizedRoutes();

    return(
        <div className="md:w-4/12 md:px-2 lg:px-4 mt-4 lg:mt-0">
            <div className="w-full h-full rounded overflow-hidden shadow-lg bg-white px-4 py-6 lg:px-8 lg:py-12 flex flex-col justify-between mb-4 lg:mb-0">
                <div className="md:h-32 lg:h-24">
                    <Heading type='action-title'>{sellingPoint.title}</Heading>
                </div>
                <div className="flex-1">
                    <Paragraph className="mt-6">{sellingPoint.description}</Paragraph>
                </div>
                <div className="mt-4 lg:mt-10">
                    <SecondaryButton
                        onClick={() => navigate(findTeacher)}>
                        {sellingPoint.button}
                    </SecondaryButton>
                </div>
            </div>
        </div>
    )
}
