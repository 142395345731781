import React from 'react'
import LogoMask from 'src/components/icons/LogoMask'
import DrumVideo from 'src/video/drum-video.mp4'

export default function VideoHero() {
    return (
        <div className="relative">
            <div className="relative z-20">
                <LogoMask
                    className="max-w-full h-auto"
                />
            </div>
            <div className="absolute inset-0 overflow-hidden m-1">
                <div className="absolute inset-0 bg-dark-blue-150 z-10" />
                <video
                    src={DrumVideo}
                    autoPlay={true}
                    muted
                    loop
                />
            </div>
        </div>
    )
}
