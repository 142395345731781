import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import BoxedLogo from 'src/components/icons/BoxedLogo'
import video from 'src/video/howitworks-nl-3.mp4'

export default function InstrumentHookUp(){
    const { t } = useTranslation();

    function showIntercom () {
        if(window.Intercom) {
            window.Intercom('show');
        }
    }

    return (
        <div className="px-4">
            <div className="container mx-auto my-12 lg:mb-24 lg:mt-0 flex flex-wrap justify-between items-center">
                <div className='w-full md:w-1/2 md:pr-4 xl:pr-0 my-6'>
                    <Heading type='head-title-wrap' tagName="h2">
                        <span className='text-dark-blue-600 block'>{t('home.noInstrument')}</span>
                        <span className='text-alt-color-600 block'>{t('home.hookUp')}</span>
                    </Heading>
                    <p className='text-lg md:text-xl mt-4'>{t('home.hookUpDesc')}</p>
                    <div className='text-xl mt-10'>
                        <a onClick={showIntercom} className="text-grey-600 inline-block text-xl link-underline">
                            {t('home.contactRoadie')}
                        </a>
                    </div>
                </div>
                <div className="relative w-full md:w-5/12 mt-4">
                    <div className="absolute z-10 top-0 left-0 -mt-4 -ml-4">
                        <BoxedLogo />
                    </div>
                    <video
                        src={video}
                        autoPlay
                        muted
                        loop
                    />
                </div>
            </div>
        </div>
    )
}
