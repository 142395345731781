import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Arrow from 'src/components/icons/Arrow'
import './Link.css'

const PRIMARY = 'inline-block text-xl font-medium text-dark-blue-600 cursor-pointer';
const UNDERLINE = 'inline-block text-xl link-underline';

function PrimaryLink({ children, route, className }) {
    return (
        <Link
            to={route}
            className={classNames(PRIMARY, className)}
        >
            {children}
        </Link>
    )
}

export function ArrowedLink({ children, route, className }) {
    return (
        <PrimaryLink
            route={route}
            className={classNames("inline-flex items-center flex-start group", className)}
        >
            <span className="mr-2">
                {children}
            </span>
            <span className="inline-block transform group-hover:translate-x-1 transition-transform duration-200">
                <Arrow />
            </span>
        </PrimaryLink>
    )
}

export function UnderlinedLink({ children, route, className }) {
    return (
        <Link
            to={route}
            className={classNames(UNDERLINE, className)}
        >
            {children}
        </Link>
    )
}

export default PrimaryLink;
