import React from 'react'

export default function Left(props) {
    return (
        <svg width={30} height={22} viewBox="0 0 10 22" {...props} cursor='pointer'>
            <title>{"chev/left"}</title>
            <path
                d="M9.437.453a.7.7 0 00-.913.034l-.07.076-8 10a.7.7 0 00-.061.785l.06.09 8 10a.7.7 0 001.152-.79l-.058-.085L1.897 11l7.65-9.563a.7.7 0 00-.034-.913l-.076-.07z"
                fill="#363636"
                fillRule="nonzero"
            />
        </svg>
    )
}

