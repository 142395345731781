import React from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import GalleryReviews from 'src/components/galleries/GalleryReviews'

export default function Reviews(){
    const { t } = useTranslation();

    return (
        <div className="px-4 my-24 bg-grey-100 py-8 lg:py-20 w-full">
            <div className='text-center'>
                <Heading type='sub-title'>{t('home.experiences')}</Heading>
            </div>
            <GalleryReviews />
        </div>
    )
}
