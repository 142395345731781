import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Heading from 'src/components/typography/Heading'
import GalleryTeachers from 'src/components/galleries/GalleryTeachers'
import { ArrowedLink } from 'src/components/buttons/Link'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import {useVisitorApi} from "../../../hooks/useApi";

export default function FindYourTeacher(){
    const { t } = useTranslation();
    const { findTeacher } = useLocalizedRoutes();
    const { getHighlightedTeachers } = useVisitorApi();
    const [ teachers, setTeachers] = useState([]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    useEffect(function fetchHighlightedTeachers(){
        getHighlightedTeachers().then(response => {
            setTeachers(shuffleArray(response.data).slice(0, 4))
        })
    }, [])

    return (
        <div className="container mx-auto px-4 my-8 lg:my-24">
            <div className="lg:flex justify-between items-center mb-10">
                <Heading type="sub-title">{t('home.whamBamSelection')}</Heading>
                <div className="mt-2 lg:mt-0">
                    <ArrowedLink
                        route={findTeacher}
                    >{t('home.seeAll')}</ArrowedLink>
                </div>
            </div>
            <div className="max-w-full overflow-scroll">
                <GalleryTeachers teachers={teachers} />
            </div>
        </div>
    )
}
