import React from 'react'

export default function LogoMask({ color, width, height, className }) {
    return (
        <svg width={width || 658} height={height || 344} viewBox="0 0 658 344" fill={color || '#A8D0E6'} className={className}>
            <path d="M658,0v344H0V0H658z M337.1,162.5h-60.2l-16.3,26.7l-17.1,0.8L153,338h63.7l14.6-26.8H288
                L292,338h59.2l-26.4-151.2l16.4-0.8L337.1,162.5z M486.6,162.5h-72.8l-3.4,19.2l-16.7,0.8L366.5,338h53.3l15.1-101.6h1.2L451.6,338
                h40.2l51.6-101.6h1.5L525.4,338h55.5L610,172.6l16.7-0.8l1.6-9.3h-79.7l-6,12.7l-16.9,0.8l-39,82.7h-1.2l-12.6-79.2l16.4-0.8
                l-0.1-0.8L486.6,162.5z M132.3,162.5H52.4l-6.6,37.3l-16.7,0.8L5,338h81.2l1.2,0c10.4-0.1,20.1-1.2,28.9-3.5
                c9.2-2.3,17.1-5.7,23.8-10.3c6.7-4.5,11.8-10.2,15.5-17c3.7-6.8,5.6-14.6,5.6-23.5c0-5.1-0.8-9.8-2.3-14.1c-1.6-4.3-3.7-8-6.4-11.3
                c-2.7-3.2-5.8-5.8-9.4-7.8c-3.5-2-7.3-3.4-11.2-4.2l0,0v-0.5l0.7-0.2c9.9-2.9,18.1-8,24.6-15.3c6.7-7.5,10-17.3,10-29.4
                c0-2.2-0.2-4.3-0.5-6.3l0,0l16.4-0.8l-0.1-0.6c-0.7-3.8-1.9-7.2-3.8-10.2c-3-4.9-7-8.9-12-11.9c-4.9-3.1-10.4-5.3-16.5-6.6
                C144.5,163.2,138.4,162.5,132.3,162.5L132.3,162.5z M87.4,266.9c5.6,0,9.9,1,12.8,3.1c3,2.1,4.4,5,4.4,8.8c0,3-0.7,5.4-2,7.3
                c-1.3,1.9-3.1,3.4-5.3,4.5c-2.2,1.1-4.8,1.8-7.8,2.2c-3,0.4-6,0.6-9.1,0.6l0,0H65.9l4.7-26.5H87.4z M278.1,222.2l5.2,45.1h-30.1
                L278.1,222.2z M95.1,206.9c4.8,0,8.7,0.8,11.7,2.5c3,1.7,4.6,4.4,4.6,8.3c0,2.8-0.6,5.1-1.7,6.9c-1.2,1.8-2.6,3.1-4.4,4.1
                c-1.8,1-3.8,1.7-6,2c-2.2,0.3-4.4,0.5-6.5,0.5l0,0H76.8l3.9-24.3H95.1z M80.3,6H38l4.4,57.4L30.9,64l5,64.9h40.6l30.9-68.1l2-0.1
                l3.3,68.2h41.1l33.9-72.1l11.9-0.6L223.2,6h-41.6l-21,51.5l-8.7,0.4l-1-52h-41.1L87.2,61.2l-6.9,0.3V6z M270.9,6h-39.7l-8.5,48.4
                L210.9,55l-13,73.9h39.9l8.3-47.2h34.9l-8.3,47.2h39.9l13.9-79l11.7-0.6L345.8,6h-40.1l-7.4,42.2h-34.9L270.9,6z M449.1,6H407
                l-24.8,40.5l-11.9,0.6l-50,81.8h44.6l10.2-18.7h39.7l2.8,18.7H459L444.2,44l11.5-0.6L449.1,6z M553.8,6h-51l-6.1,34.8l-11.7,0.6
                l-15.4,87.5H507l10.5-71.2h0.9l10.9,71.2h28.2l36.1-71.2h1L581,128.9h38.9l16.6-94.4l11.7-0.6L653,6v0h-55.8l-14.4,30.6l-11.9,0.6
                l-17.1,36.2H553l-5.5-34.5l11.5-0.6l-0.1-0.6L553.8,6z M407.9,47.8l3.6,31.6h-21.1L407.9,47.8z"/>
        </svg>
    )
}
